import "./events_carrousel.css";

document.addEventListener("turbolinks:load", function() {
  let bodyId = document.querySelector("body").getAttribute("id");
  let watchedPages = ["staticPagesHome", "eventsShow"];

  if (watchedPages.indexOf(bodyId) > -1) {
    new imagesLoaded(document.body, { background: true }, function() {
      new Swiper("#eventsSlider", {
        slidesPerView: 1.2,
        spaceBetween: 15,
        slidesOffsetBefore: 15,
        centeredSlides: false,
        loop: false,
        preventInteractionOnTransition: true,
        threshold: 0.04,
        breakpoints: {
          1600: {
            slidesPerView: 3.8,
            spaceBetween: 40,
            slidesOffsetBefore: 140,
          },
          1400: {
            slidesPerView: 3.8,
            spaceBetween: 40,
            slidesOffsetBefore: 80,
          },
          1025: {
            slidesPerView: 2.2,
            spaceBetween: 15,
            slidesOffsetBefore: 15,
          },
          676: {
            slidesPerView: 2.2,
            spaceBetween: 15,
            slidesOffsetBefore: 15,
          },
        }
      });
    });
  }
});
