import "./infinite_scroll.css";

// control del scroll (copy&paste mejorable)
class Viewport {
  constructor() {
    this.positions = {
        top: 0,
        bottom: 0,
        height: 0
    };
  }

  setViewportTop() {
    this.positions.top = window.pageYOffset;
    return this.positions;
  }

  setViewportBottom() {
    this.positions.bottom = this.positions.top + this.positions.height;
    return this.positions;
  }

  setViewportHeight() {
    this.positions.height = document.documentContainer.clientHeight;
    return this.positions;
  }

  setViewportAll() {
    this.positions.top = window.pageYOffset;
    this.positions.bottom = this.positions.top + this.positions.height;
    this.positions.height = document.documentContainer.clientHeight;
    return this.positions;
  }
}

let infScrContainer;
let infScrTrigger;

let containerHeight;
let containerTop;
let containerBottom;

export const viewport = new Viewport();

function debounce(func, wait, immediate) {
  let timeout;

	return function() {
    let context = this, args = arguments;

		let later = function() {
      timeout = null;

			if (!immediate) {
        func.apply(context, args);
      }
    };

    let callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

		if (callNow) {
      func.apply(context, args);
    }
	};
};

function triggerIsVisible() {
  getContainerOffset();

  return containerBottom > viewport.positions.top && containerTop < viewport.positions.bottom;
}

function getContainerOffset() {
  const positions = infScrContainer.getBoundingClientRect();
  containerHeight = positions.height;
  containerTop = positions.top + viewport.positions.top;
  containerBottom = containerHeight + containerTop;
}

// scroll infinito

let request;
let locked = false;
let lastPage = 0;

window.loadPage = function() {
  if (!locked && triggerIsVisible()) {
    locked = true;

    let page = infScrTrigger.getAttribute("data-page");

    if (page != lastPage && page != -1) {
      lastPage = page;
      request.open("POST", `${infScrTrigger.getAttribute("data-url")}?page=${page}`);
      request.send();
    }
  }
}

function initInfinteScroll() {
  locked = false;
  lastPage = 0;

  infScrContainer = document.querySelector("#infiniteScrollContainer");

  if (infScrContainer) {
    infScrTrigger = document.querySelector("#infiniteScrollTrigger");

    request = new XMLHttpRequest();

    request.onreadystatechange = function(data) {
      if (request.readyState < 4) {
        infScrTrigger.classList.remove("ko");
        infScrTrigger.classList.add("loading");
      }
      else if (request.readyState == 4) {
        let page;

        if (request.status == 200) {
          const responseData = JSON.parse(data.target.responseText);

          page = responseData.page;

          const items = responseData.items;

          infScrTrigger.setAttribute("data-page", page);

          for(let item of items) {
            let itemHtml = document.createElement("div");
            itemHtml.innerHTML = item;

            infScrContainer.insertBefore(itemHtml.firstElementChild, infScrContainer.querySelector(".dummy"));
          }

          locked = false;

          if (page == -1) {
            infScrTrigger.classList.remove("error", "ko");
            infScrTrigger.classList.add("ok");
          }
        } else {
          infScrTrigger.classList.add("ko");
          locked = false;
        }

        infScrTrigger.classList.remove("loading");
      }
    }

    document.addEventListener("scroll", debounce(loadPage, 100));
  }
}

document.addEventListener("turbolinks:load", function() {
  initInfinteScroll();
});
