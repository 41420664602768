import "./gallery_viewer.css";

let galleryContainer;
let galleryContainerImg;

document.addEventListener("turbolinks:load", function() {
  galleryContainer = document.querySelector("#galleryContainer");

  if (galleryContainer) {
    galleryContainerImg = galleryContainer.querySelector("img");

    document.addEventListener("click", function(event) {
      const trigger = event.target;

      if (trigger.classList.contains("gallery-viewer-img")) {
        let img = trigger.getAttribute("data-url") || trigger.getAttribute("src");

        if (img) {
          galleryContainerImg.setAttribute("src", img);
          galleryContainer.classList.add("visible");
        }
      }
    });

    galleryContainer.querySelector(".close").addEventListener("click", function() {
      galleryContainerImg.setAttribute("src", "");
      galleryContainer.classList.remove("visible");
    });
  }
});
