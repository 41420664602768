// JS
import "../scripts/global.js";
import "../scripts/frontend/home.js";
import "../scripts/frontend/events.js";
import "../scripts/frontend/teachers.js";
import LocomotiveScroll from "locomotive-scroll";

// Styles
import "../stylesheets/frontend.css";
import "../stylesheets/frontend/home.css";
import "../stylesheets/frontend/about_us.css";
import "../stylesheets/frontend/become_member.css";
import "../stylesheets/frontend/locomotive_scroll.css";

// Components
import "../components/menu/menu";
import "../components/cookies_modal/cookies_modal";
import "../components/gallery/gallery";
import "../components/gallery_viewer/gallery_viewer";
import "../components/video_viewer/video_viewer";
import "../components/event_card/event_card";
import "../components/teacher_card/teacher_card";
import "../components/events_carrousel/events_carrousel";
import "../components/membership_form/membership_form";
import "../components/booking_form/booking_form";
import "../components/infinite_scroll/infinite_scroll";
import "../components/social_media/social_media";
import "../components/langs_select/langs_select";
import "../components/footer/footer";

let prevWindowWidth = window.innerWidth;

document.addEventListener("turbolinks:load", function() {
  // recarga la página al redimensionarla y cruzar determinado umbral
  // para forzar que algunos elementos se ajusten correctamente

  let bodyId = document.querySelector("body").getAttribute("id");
  let watchedPages = ["staticPagesHome", "eventsShow"];

  if (watchedPages.indexOf(bodyId) > -1) {
    window.addEventListener("resize", debounce(function() {
      let windowWidth = window.innerWidth;

      if ((prevWindowWidth <= 1024 && windowWidth > 1024) || (prevWindowWidth > 1024 && windowWidth <= 1024)) {
        prevWindowWidth = windowWidth;
        location.reload();
      }
    }, 25));
  }

  new imagesLoaded(document.body, { background: true }, function() {
    window.setTimeout(function() {
      let locomotiveScroll = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
        getDirection: true,
        reloadOnContextChange: true,
        resetNativeScroll: true,
        firefoxMultiplier: 100,
        tablet: {
          smooth: true,
          getDirection: true,
        },
        smartphone: {
          smooth: false,
          getDirection: true,
        }
      });
      window.locomotive = locomotiveScroll
      locomotiveScroll.update();
    }, 80);
  });

  // newsletter
  let newsletterForm = document.querySelector("#newsletterForm");

  if (newsletterForm) {
    newsletterForm.addEventListener("ajax:beforeSend", function(event) {
      event.target.classList.add("sending");
    });

    newsletterForm.addEventListener("ajax:success", function(event) {
      let form = event.target;

      form.classList.remove("sending", "error");
      form.classList.add("done");

      form.querySelector(".error").classList.remove("visible");

      let submitButton = form.querySelector(".submit");
      let SUCCESS_MSG = {"es": "¡Suscrito!", "ca": "Subscrit!", "en": "Subscribed!"};

      submitButton.querySelector("span").innerText = SUCCESS_MSG[document.documentElement.lang];

      form.reset();
    });

    newsletterForm.addEventListener("ajax:error", function(event) {
      let form = event.target;

      form.querySelector(".error").classList.add("visible");

      form.classList.remove("sending");
      form.classList.add("error");
    });
  }
});
