import "./gallery.css";

document.addEventListener("turbolinks:load", function() {
  let bodyId = document.querySelector("body").getAttribute("id");
  let watchedPages = ["eventsShow"];

  if (watchedPages.indexOf(bodyId) > -1) {
    new imagesLoaded(document.body, { background: true }, function() {
      new Swiper(".gallery .swiper-container", {
        slidesPerView: "auto",
        loop: false,
        spaceBetween: 10,
        speed: 500,
        threshold: 0.05,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      });
    });
  }
});
