import "./menu.css";

document.addEventListener("turbolinks:load", function() {
  const menu = document.querySelector("#menu");

  if (menu) {
    let outterlayer = menu.querySelector(".menu-outter-layer");

    outterlayer.addEventListener("click", function () {
      menu.classList.remove("open");
    });

    let menuToggle = menu.querySelector("#menuToggle");

    menuToggle.addEventListener("click", function() {
      menu.classList.toggle("open");
    });
  }
});
