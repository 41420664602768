import "./langs_select.css";

document.addEventListener("turbolinks:load", function() {
  const langs = document.querySelectorAll(".langs-select select");

  for (let lang of langs) {
    lang.addEventListener("change", function() {
      window.location.href = lang.selectedOptions[0].getAttribute("data-link");
    });
  }
});
