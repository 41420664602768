import "./video_viewer.css";

let playerExists;
let YTloaded = false;
let playerIDs = [];
let intents = 0;

window.onYouTubeIframeAPIReady = function() {
  try {
    for (let playerID of playerIDs) {
      let YTplayer = new YT.Player(playerID, {
        playerVars: {
          modestbranding: 1,
          loop: 0,
          showInfo: 0,
          rel: 0,
          color: "white",
          showinfo: 0,
          controls: 1,
          suggestedQuality: "large"
        },
      });

      if (YTplayer) {
        playerExists = true;
      }

      initVideoViewer(YTplayer, playerID);
    }
  }
  catch(e) {
    ++intents;

    if (intents < 4) {
      if (!YTloaded) {
        loadYoutube();
      }

      onYouTubeIframeAPIReady();
    }
  }
}

function initVideoViewer(YTplayer, playerID) {
  let videoPresenters = document.querySelectorAll(".video-presenter");

  for (let videoPresenter of videoPresenters) {
    const videoPlayer = document.querySelector(`#${videoPresenter.getAttribute("data-player")}`);
    const player = videoPlayer.querySelector(`#${playerID}`);

    videoPresenter.addEventListener("click", function() {
      window.setTimeout(function() {
        if (videoPlayer.classList.contains("youtube")) {
          YTplayer.loadVideoById(player.getAttribute("data-url"));
          YTplayer.playVideo();
        }
        else {
          player.contentWindow.postMessage('{"method":"play"}', '*');
        }
      }, 100);

      videoPlayer.classList.add("visible");
    });

    if (videoPlayer.classList.contains("-as-modal")) {
      videoPlayer.addEventListener("click", function () {
        videoPlayer.classList.remove("visible");

        if (videoPlayer.classList.contains("youtube")) {
          YTplayer.pauseVideo();
        } else {
          player.contentWindow.postMessage('{"method":"pause"}', "*");
        }
      });
    }
  }
}

function loadYoutube() {
  if (playerExists) {
    return;
  }

  let tag = document.createElement('script');
  tag.src = "https://www.youtube.com/iframe_api";
  let firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  YTloaded = true;
};

loadYoutube();

document.addEventListener("turbolinks:load", function() {
  let videoPlayers = document.querySelectorAll(".player");

  for (let videoPlayer of videoPlayers) {
    let playerID = videoPlayer.getAttribute("id");

    if (videoPlayer.classList.contains("youtube")) {
      playerIDs.push(playerID);
    }
    else {
      initVideoViewer(null, playerID);
    }
  }

  onYouTubeIframeAPIReady();
});
