// Stylesheets
import "../../stylesheets/frontend/teachers.css";

let prevWindowWidth = window.innerWidth;

function loadParticipatsIndexSlider() {
  if (window.innerWidth > 1300) {
    let offset = document.querySelector("header").offsetWidth;

    new Swiper("#teachersSlider", {
      slidesPerView: "auto",
      slidesOffsetBefore: offset,
      slidesOffsetAfter: 0,
      speed: 100,
      freeModeMomentum: false,
      freeMode: true,
      mousewheel: {
        releaseOnEdges: true,
      },
      centeredSlides: false,
      loop: false,
      preventInteractionOnTransition: true,
      threshold: 0.2,
    });
  }
  else {
    new Swiper("#teachersSlider", {
      slidesPerView: "auto",
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      speed: 350,
      centeredSlides: false,
      loop: false,
      preventInteractionOnTransition: true,
      threshold: 0.05,
    });
  }
}

document.addEventListener("turbolinks:load", function() {
  if (document.querySelector("body").getAttribute("id") == "teachersIndex") {
    new imagesLoaded(document.body, { background: true }, function() {
      loadParticipatsIndexSlider();

      window.addEventListener("resize", debounce(function() {
        let windowWidth = window.innerWidth;

        if ((prevWindowWidth <= 1300 && windowWidth > 1300) || (prevWindowWidth > 1300 && windowWidth <= 1300)) {
          prevWindowWidth = windowWidth;
          location.reload();
        }
      }, 25));
    });
  }
});
