// Styles
import "../../stylesheets/frontend/events.css";

document.addEventListener("turbolinks:load", function() {
  if (document.body.id == "eventsShow") {
    new imagesLoaded(document.body, { background: true }, function() {
      let article = document.querySelector("article");

      if (article) {
        // procesa las imágenes del artículo quitando espacios en blanco no deseados
        let articleImgs = article.querySelectorAll("p > img, p picture img.desktop-img");

        for (let img of articleImgs) {
          let imgWrapper = img.parentNode;

          let source = imgWrapper.querySelector("source");

          if (source) {
            imgWrapper.parentNode.innerHTML = imgWrapper.parentNode.innerHTML.replace(/&nbsp;/g, '');
          }
        }
      }
    });
  }
});
